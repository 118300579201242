<template>
  <div class="full-heigth">
    <div class="contenedor">
      <rutaPagina :volver="false" :ruta="ruta" />

      <div class="row glass">
        <indicador
          icono="bx bx-cart-alt"
          texto="Ordenes de compras abiertas"
          :cantidad="20"
          :ultimasemana="false"
        />
        <indicador
          icono="bx bx-package"
          texto="Entrada de mercancías/servicios"
          :cantidad="20"
        />
        <indicador icono="bx bx-file" texto="Nuevas facturas recibidas" :cantidad="20" />
        <indicador icono="bx bx-money" texto="Nuevos pagos realizados" :cantidad="20" />
      </div>

      <div class="row glass">

        <tituloPagina texto="Proceso de ventas" />

        <div class="containerxd">
          <div class="primero">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-file-lines"></i>
                <p>Oferta de venta</p>
                <i class="fa-solid fa-arrow-down-long size-arrow"></i>
            </div>
          </div>
          <div class="segundo">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-file-invoice-dollar"></i>
                <p>Factura anticipo cliente</p>
                <i class="fa-solid fa-arrow-down-long size-arrow"></i>
            </div>            
          </div>
          <div class="tercero">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-file-lines"></i>
                <p>Pedido de cliente</p>
            </div>
          </div>
          <div class="linea1">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-arrow-right-long size-arrow"></i>
            </div>
          </div>
          <div class="cuarto">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-cart-shopping"></i>
                <p>Entrega</p>
                <i class="fa-solid fa-arrow-down-long size-arrow"></i>
            </div>
          </div>
          <div class="linea2">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-arrow-right-long size-arrow"></i>
            </div>
          </div>
          <div class="quinto">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-file-invoice-dollar"></i>
                <p>Factura de deudores</p>
                <i class="fa-solid fa-arrow-down-long size-arrow"></i>
            </div>             
          </div>
          <div class="linea3">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-arrow-right-long size-arrow"></i>
            </div>
          </div>
          <div class="sexto">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-hand-holding-dollar"></i>
                <p>Cobros</p>
            </div> 
          </div>
          <div class="septimo">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-cart-shopping fa-flip-horizontal"></i>
                <p>Devoluciones</p>
            </div>
          </div>
          <div class="octavo">
            <div class="box-contenedor-icono">
                <i class="fa-solid fa-circle-dollar-to-slot"></i>
                <p>Abono de clientes</p>
            </div>            
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import indicador from "@/components/Inidicador";
import rutaPagina from "@/components/RutaPagina";
import { onMounted } from "vue";
import tituloPagina from '../../components/Titulopagina'

export default {
  name: "Inicio",
  setup(props) {
    const ruta = [{ nombre: "Inicio" }];
    return {
      ruta,
    };
  },
  components: {
    indicador,
    rutaPagina,
    tituloPagina,
  },
};
</script>

<style scope>
.glass {
  margin-top: 1rem;
}

.containerxd {  
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 9px 4px;
  grid-auto-flow: row dense;
  grid-template-areas:
    "primero . . . segundo . ."
    "tercero linea1 cuarto linea2 quinto linea3 sexto"
    ". . septimo . octavo . .";
}
.primero { grid-area: primero; }

.segundo { grid-area: segundo; }

.tercero { grid-area: tercero; }

.linea1 { grid-area: linea1; }

.cuarto { grid-area: cuarto; }

.linea2 { grid-area: linea2; }

.quinto { grid-area: quinto; }

.linea3 { grid-area: linea3; }

.sexto { grid-area: sexto; }

.septimo { grid-area: septimo; }

.octavo { grid-area: octavo; }

.box-contenedor-icono {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box-contenedor-icono i {
  font-size: 2.6em;
  color: #4a4b4c94;
}
.box-contenedor-icono p {
  font-size: 0.8em;
  text-align: center;
  color: black;
}
.icono-factura {
    width: 2.8rem;
    height: 2.8rem;
    padding: 0.3rem;
    background-image: url(/img/ic_factura.08d15fb7.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.size-arrow{
  font-size: 1.4rem !important;
  padding: 4px;
}
</style>
