<template>
    <div class="col-lg-3 col-md-6 col-xs-12 contenedor-indicador" >
        <span>
            <i :class='icono'></i>
            {{ texto }}
        </span>
        <div class="valor">
            <h3># <strong>{{cantidad}}</strong></h3>
            <span v-if="ultimasemana" class="badge rounded-pill bg-light text-dark">Última<br/>semana</span>
        </div>
    </div>
</template>

<script>
export default {
    name:'Indicador incio',
    props: {
       icono: { type: String, required: true },
       texto: { type: String, required: true },
       cantidad: { type: Number, required: true },
       ultimasemana: { type: Boolean, default: true },
    },
    setup(props) {

    }
}
</script>

<style scope>
    .contenedor-indicador{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
    .contenedor-indicador i {
        font-size: 1.8em;
        color: var(--ng-secundario);
        padding-right: 0.4rem;
    }
    .contenedor-indicador span{
        font-size: 0.72rem;
        display: flex;
        flex-flow: row;
    }
    .contenedor-indicador h3{
        color: var(--ng-placeholder);
        font-size: 1.6em;
        margin: 0px;
    }
    .contenedor-indicador strong{
        font-size: 1.3em;
        color: var(--ng-grisclaro);
        font-weight: 800;
    }
    .contenedor-indicador .valor{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }
    .contenedor-indicador .valor span{
        font-size: 0.6rem;
        background-color: #075ffe26 !important;
        padding: 0.2rem 0.6rem 0.2rem 0.6rem;
        color: var(--ng-primario) !important;
    }
</style>